import { Text } from "grommet";
import React from "react";

const StandardText = ({ label, size = 'small', color = COLOR.black, bold = false, ...rest }) => (
    <Text size={size} color={color} 
        style={{fontWeight: bold ? 'bold' : 'normal'}}
        {...rest}
    >
        {label}
    </Text>
)

export default StandardText;
const COLOR = {
    black: "#555",
    background: "#F8F8F8",
    error: "#dc3545",
    dark: "#999"
}
export {
    COLOR
}