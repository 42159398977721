const { baseAPI } = require('../config/config.json');
const axios = require('axios');
const storageService = require('./storage_service');
const buildHeader = () => ({
    'Content-type': 'application/json',
    'Authorization': `Bearer ${storageService.getToken()}`
});

const login = (username, password) => {
    let body = {
        identifier: username,
        password
    }
    return axios.post(`${baseAPI}/api/auth/local`, body, { headers: { 'Content-type': 'application/json' } })
}

const forgotPassword = (email) => {
    let body = {
        email
    }
    return axios.post(`${baseAPI}/api/auth/forgot-password`, body, { headers: { "content-type": "application/json" } })
}

const changePassword = (body) => {
    return axios.post(`${baseAPI}/api/auth/change-password`, body, { headers: buildHeader() })
}

const updateUser = (user) => {
    return axios.put(`${baseAPI}/api/users/${user.id}`, user, { headers: buildHeader() })
}

const resetPassword = ({ password, passwordConfirmation, code }) => {
    let body = {
        passwordConfirmation,
        password,
        code
    }
    return axios.post(`${baseAPI}/api/auth/reset-password`, body, { headers: { "content-type": "application/json" } })
}

const logout = () => {
    storageService.clearAuth();
}

const verifyConnected = () => {
    return axios.get(`${baseAPI}/status`, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    })
}

const getPartner = (userId, jwt) => {
    const params = {
        "filters[$and][0][account][id][$eq]": userId,
    }
    return axios.get(`${baseAPI}/api/partners`, {
        headers: {
            'Authorization': `Bearer ${jwt}`
        },
        params
    })
}

const getVersion = () => {
    return axios.get(`${baseAPI}/api/public/version`)
}

module.exports = {
    login,
    verifyConnected,
    logout,
    resetPassword,
    forgotPassword,
    changePassword,
    updateUser,
    getPartner,
    getVersion,
}